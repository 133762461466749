<template>
  <div
      v-if="isMounted"
      id="filter"
      class="ps-filter-template w-100"
      :class="{'d-block': isRowFilter, 'd-flex': !isRowFilter}"
  >
    <!-- Single filter settings -->
    <template v-if="!isArrayFilterSettings">
      <div class="filter-container" :style="isRowFilter ? 'display: table-caption;' : ''">
        <DynamicFormElement
            v-for="(item, key) in filterSettings"
            :key="getElementKey(item, key)"
            :filter-key="key"
            :item="item"
            :models.sync="models"
            @search="search"
        >
          <template v-for="(_, slot) in $scopedSlots" #[slot]="props">
            <slot :name="slot" v-bind="props" />
          </template>
        </DynamicFormElement>
        <div class="d-inline-flex" style="gap: 3px;">
          <b-button v-if="showImport" variant="success" @click.prevent="importFile">{{ importButtonText }}</b-button>
          <b-button v-if="!hideSearch" variant="primary" @click.prevent="search">{{ searchButtonText }}</b-button>
          <b-button v-if="showExport" variant="success" @click.prevent="exportToCSV">CSV Export</b-button>
        </div>
      </div>
    </template>

    <!-- Multiple filter settings -->
    <template v-else>
      <div
          v-for="(filterSetting, i) in filterSettings"
          :key="i"
          :class="{'row': hasGroupName(filterSetting)}"
      >
        <!-- Case 1: filterSetting is an object (grouped) -->
        <template v-if="!Array.isArray(filterSetting) && hasGroup(filterSetting)">
          <div v-if="getGroupName(filterSetting)" class="col-1 mr-2 mt-2">
            {{ getGroupName(filterSetting) }}
          </div>
          <div :class="getContainerClass(i, filterSetting)" :style="isRowFilter ? getGroupStyle(filterSetting) : ''">
            <DynamicFormElement
                v-for="(item, key) in filterSetting"
                :key="getElementKey(item, key)"
                :filter-key="key"
                :item="item"
                :models.sync="models"
                @search="search"
            >
              <template v-for="(_, slot) in $scopedSlots" #[slot]="props">
                <slot :name="slot" v-bind="props" />
              </template>
            </DynamicFormElement>
          </div>
        </template>

        <!-- Case 2: filterSetting is an array with dynamic fields -->
        <template v-else>
          <div v-if="filterSetting[0]" class="col-1 pr-2 mt-2">
            {{ filterSetting[0].groupName }}
          </div>
          <div v-if="filterSetting[0]" class="border card p-2 col-11">
            <div
                v-for="(dynamicField, idx) in filterSetting.slice(1)"
                :key="idx"
            >
              <div v-if="idx > 0" class="font-weight-bolder mb-1">AND</div>
              <div class="filter-container">
                <DynamicFormElement
                    v-for="(item, key) in dynamicField"
                    :key="getElementKey(item, key)"
                    :filter-key="key"
                    :item="item"
                    :models.sync="models"
                    @search="search"
                >
                  <template v-for="(_, slot) in $scopedSlots" #[slot]="props">
                    <slot :name="slot" v-bind="props" />
                  </template>
                </DynamicFormElement>
              </div>
            </div>
            <div>
              <b-button @click="$emit('addDynamicField')" variant="info">
                {{ dynamicFieldButtonAdd }}
              </b-button>
            </div>
          </div>
        </template>
      </div>
      <div class="d-inline-flex ml-1" style="height: fit-content; gap: 3px;">
        <b-button v-if="showImport" variant="success" @click.prevent="importFile">{{ importButtonText }}</b-button>
        <b-button variant="primary" @click.prevent="search">{{ searchButtonText }}</b-button>
        <b-button v-if="showExport" variant="success" @click.prevent="exportToCSV">CSV Export</b-button>
      </div>
    </template>
  </div>
</template>

<script>
import DynamicFormElement from '@/main/custom-components/DynamicForm/DynamicFormElement.vue'

export default {
  name: 'FilterComponent',
  inheritAttrs: false,
  components: { DynamicFormElement },
  props: {
    value: { type: Object },
    filterSettings: { type: [Object, Array] },
    hideSearch: { type: Boolean, default: false },
    isRowFilter: { type: Boolean, default: false },
    injectActions: { type: Object, default: () => ({}) },
    firstSearch: { type: Boolean, default: true },
    showExport: { type: Boolean, default: false },
    showImport: { type: Boolean, default: false },
    disableDates: { type: Array, default: () => [] },
    useQueryUrl: { type: Boolean, default: true },
    searchButtonText: { type: String, default: 'Search' },
    importButtonText: { type: String, default: 'Import' },
    dynamicFieldButtonAdd: { type: String, default: 'Add Another' }
  },
  data () {
    return {
      models: {},
      isMounted: false,
      suggestions: {},
      prevModels: {},
      isReadyToSendActionLog: true
    }
  },
  computed: {
    // Check if filterSettings is an array
    isArrayFilterSettings () {
      return Array.isArray(this.filterSettings)
    }
  },
  methods: {
    // Helper: Generate a unique key for DynamicFormElement
    getElementKey (item, key) {
      return item.refresh_uuid ? `${key}-${item.refresh_uuid}` : key
    },
    // Helper: Check if the filter setting object has a group
    hasGroup (filterSetting) {
      return !!Object.values(filterSetting)[0]
    },
    // Helper: Return the group name if available
    getGroupName (filterSetting) {
      const first = Object.values(filterSetting)[0]
      return first ? first.groupName : ''
    },
    // Helper: Check if the filter setting has a group name
    hasGroupName (filterSetting) {
      return !!this.getGroupName(filterSetting)
    },
    // Helper: Get group style or fallback style
    getGroupStyle (filterSetting) {
      const first = Object.values(filterSetting)[0]
      return first && first.groupStyle ? first.groupStyle : 'display: table-caption;'
    },
    // Helper: Return container class based on group presence
    getContainerClass (i, filterSetting) {
      const hasGroup = this.hasGroupName(filterSetting)
      return {
        'filter-container': i === 0 || hasGroup,
        'border card p-2 col-10': hasGroup
      }
    },

    // ---------------- Filter & Query Methods ----------------
    search () {
      const isChanged = JSON.stringify(this.models) !== JSON.stringify(this.prevModels)
      if (this.isReadyToSendActionLog) {
        this.sendActionLog(`change filter search ${this.$route.path} with values ${JSON.stringify(this.models)}`)
      }
      if (isChanged) this.$emit('changed')
      if (this.useQueryUrl) {
        this.setParamsFilterToUrl(isChanged)
        this.generateFilterValuesByUrl()
      }
      this.$emit('input', this.models)
      this.$emit('search')
      this.prevModels = JSON.parse(JSON.stringify(this.models))
    },
    exportToCSV () {
      this.$emit('input', this.models)
      this.$emit('exportToCSV')
    },
    importFile () {
      this.$emit('input', this.models)
      this.$emit('importFile')
    },
    setParamsFilterToUrl (isChanged) {
      const queryParams = new URLSearchParams(window.location.search)
      for (const key in this.models) {
        const currentValue = this.models[key]
        const currentFilterSetting = Array.isArray(this.filterSettings) ? Object.assign({}, ...this.filterSettings)[key] : this.filterSettings[key]
        if (currentFilterSetting) {
          if (currentFilterSetting.type === 'daterange') {
            if (currentValue.fromDate) queryParams.set(`filter[${key}From]`, currentValue.fromDate)
            if (currentValue.toDate) queryParams.set(`filter[${key}To]`, currentValue.toDate)
          } else {
            const currentQueryParam = queryParams.get(`filter[${key}]`)
            if (
              currentValue ||
                (currentValue === '' && currentQueryParam) ||
                currentFilterSetting.type === 'checkbox_yes_no' ||
                currentValue === 0
            ) {
              queryParams.set(`filter[${key}]`, currentValue)
            }
            const appendModel = currentFilterSetting.append && this.models[currentFilterSetting.append]
            if (appendModel) {
              queryParams.set(`filter[${currentFilterSetting.append}]`, appendModel)
            }
            if (currentFilterSetting.options && currentFilterSetting.optionModel) {
              const optionModel = this.models[currentFilterSetting.optionModel]
              if (optionModel) {
                const optionTrackByValues = optionModel.map(option => option[currentFilterSetting.optionTrackBy])
                queryParams.set(`filter[${currentFilterSetting.optionModel}]`, optionTrackByValues.join(','))
              }
            }
          }
        }
      }
      if (isChanged) queryParams.set('page', 1)
      if (queryParams.size > 0) {
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`
        window.history.replaceState({}, '', newUrl)
      }
    },
    generateFilterValuesByUrl () {
      const queryParams = new URLSearchParams(window.location.search)
      for (const key in this.models) {
        const currentFilterSetting = Array.isArray(this.filterSettings) ? Object.assign({}, ...this.filterSettings)[key] : this.filterSettings[key]
        if (currentFilterSetting) {
          if (currentFilterSetting.type === 'daterange') {
            const fromParam = queryParams.get(`filter[${key}From]`)
            const toParam = queryParams.get(`filter[${key}To]`)
            if (fromParam && toParam) {
              this.models[key].fromDate = fromParam
              this.models[key].toDate = toParam
            }
          } else if (['checkbox', 'multiselect'].includes(currentFilterSetting.type)) {
            const param = queryParams.get(`filter[${key}]`)
            this.models[key] = param ? param.split(',') : []
          } else if (currentFilterSetting.type === 'auto-suggest') {
            const param = queryParams.get(`filter[${key}]`)
            if (param) {
              this.models[key] = param
              currentFilterSetting.defaultValue = param
              if (currentFilterSetting.keyText === currentFilterSetting.keyValue) {
                currentFilterSetting.value = param
              }
            }
          } else {
            const param = queryParams.get(`filter[${key}]`)
            if (param) this.models[key] = param
            if (currentFilterSetting.append && queryParams.get(`filter[${currentFilterSetting.append}]`)) {
              this.models[currentFilterSetting.append] = queryParams.get(`filter[${currentFilterSetting.append}]`)
            }
          }
        }
      }
    },
    generateModels () {
      if (Array.isArray(this.filterSettings)) {
        this.filterSettings.forEach(filterSetting => {
          for (const key in filterSetting) {
            this.models[key] = this.$route.query[`filter[${key}]`] ?? filterSetting[key].defaultValue
          }
        })
      } else {
        for (const key in this.filterSettings) {
          if (this.$route.query[`filter[${key}]`]) {
            if (Array.isArray(this.filterSettings[key].defaultValue)) {
              this.models[key] = this.$route.query[`filter[${key}]`].split(',')
            } else {
              this.models[key] = this.$route.query[`filter[${key}]`]
            }
          } else {
            this.models[key] = this.filterSettings[key].defaultValue
          }
        }
      }
      this.prevModels = JSON.parse(JSON.stringify(this.models))
    }
  },
  created () {
    this.generateModels()
  },
  async mounted () {
    this.isMounted = true
    if (this.firstSearch) {
      this.isReadyToSendActionLog = false
      await this.search()
      this.isReadyToSendActionLog = true
    }
  }
}
</script>

<style lang="scss">
.ps-filter-template {
  margin-bottom: 10px;
}
.ps-filter-template .filter-container {
  display: table;
  width: 100%;
}
.ps-filter-template .filter-container > * {
  clear: none;
  float: left;
  margin-right: 10px;
}
.ps-filter-template .leftx {
  display: flex;
}
.ps-filter-template .leftx li {
  margin-right: 10px;
  font-size: 14px;
}
.ps-filter-template .input-select .vs-select--input {
  max-width: 150px;
}
.ps-filter-template .custom-checkbox {
  margin-top: 5px;
  margin-bottom: 5px;
}
.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 280px;
}
#autosuggest {
  width: 100%;
  display: block;
}
#autosuggest .autosuggest__results-item--highlighted {
  background-color: rgba(51, 217, 178, 0.2);
}
#autosuggest .autosuggest__results-container {
  position: relative;
  z-index: 999;
}
#autosuggest .autosuggest__results-container .autosuggest__results {
  background-color: #fff;
  padding-top: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  width: 100%;
  overflow-y: auto;
  max-height: 40vh;
}
#autosuggest .autosuggest__results-container .autosuggest__results ul {
  list-style-type: none;
  padding-left: 10px;
}
#autosuggest .autosuggest__results-container .autosuggest__results-item {
  padding: 5px;
  cursor: pointer;
}

.custom-radio .custom-control-label {
  margin-left: 5px;
}
</style>
